import { setLocale } from 'yup';

export const customizeYup = () => {
  setLocale({
    mixed: {
      required: 'Это обязательное поле',
    },
    string: {
      email: 'Введён неккоректный электронный адрес',
      min: 'Слишком коротко, минимум ${min} символа',
    },
  });
};
