import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.palette.grey.background};
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 20px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-left: ${({ theme }) => theme.spaces.s};
  margin-right: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    max-width: 500px;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 130px;
  max-height: 130px;
`;

export const FormWrapper = styled(BoxWrapper)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spaces.m};
`;

export const Privacy = styled.div`
  margin-top: ${({ theme }) => theme.spaces.l};
  font-size: 13px;
  opacity: 0.7;
  text-align: center;
`;
