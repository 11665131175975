import Login from 'screens/Login';
import { withNotAuthorizedUser } from '@shared/server-side-guards/not-authorized-user';
import { LoginFormsLayout } from '@components/login-forms-layout';

export const getServerSideProps = withNotAuthorizedUser();

Login.layout = ({ children }) => LoginFormsLayout({ children, title: 'Войти' });
Login.headProps = {
  title: 'Вход / Kritod.ru',
  description: 'Войти в Kritod',
};

export default Login;
