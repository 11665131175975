import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { CONTINUE } from 'types/routesQueryNames';
import { Nullable } from 'types';

export function useContinuePath(): Nullable<string> {
  const router = useRouter();
  const search = router.query;
  // Почему то TS теряет тип, если не присвоить переменной
  const searchContinue = search[CONTINUE];

  return useMemo(() => {
    if (!searchContinue) return null;
    if (Array.isArray(searchContinue)) return searchContinue.join(',');
    return searchContinue;
  }, [searchContinue]);
}
