import { useFormik, Form, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { continueParamsFactory, routes } from '../../../routes';
import { LoginFormValues } from './types';
import { validationSchema } from './validation.schema';
import { buildInitialValues } from './initial-values';
import { FieldWrapper, SubmitButton, ErrorText, ExtraActions, SuccessRegistration } from './styled';
import TextField from '@lib/Components/fields/TextField';
import { useContinuePath } from '@shared/hooks/use-continue-path';
import {
  applyInputTransformers,
  toLowerCaseValueTransformer,
  offSpacesValueTransformer,
} from '@lib/Components/fields/input-value-helpers';
import { Nullable } from 'types';

interface Props {
  onFormSubmit(values: LoginFormValues): Promise<void>;

  registrationEmail: Nullable<string>;
}

export const LoginForm = ({ onFormSubmit, registrationEmail }: Props) => {
  const [isLoading, setLoadingStatus] = useState(false);
  const [errorText, setErrorText] = useState<Nullable<string>>(null);
  const [showPassword, setShowPassword] = useState(false);
  const continuePath = useContinuePath();
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (values: LoginFormValues) => {
    setLoadingStatus(true);
    try {
      await onFormSubmit(values);
    } catch (err) {
      setErrorText((err as Error).message);
      setLoadingStatus(false);
    }
  };

  const form = useFormik<LoginFormValues>({
    initialValues: buildInitialValues(registrationEmail),
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setErrorText('');
  }, [form.values]);

  return (
    <FormikProvider value={form}>
      {registrationEmail && (
        <SuccessRegistration>
          Поздравляем, регистрация завершена. Ваш пароль отправлен по адресу
          {` `}
          {registrationEmail}. Позже вы сможете изменить его на странице профиля. Если письмо не доходит, проверьте
          папку спам или обратитесь в службу поддержки.
        </SuccessRegistration>
      )}
      <Form>
        <FieldWrapper>
          <TextField
            disabled={isLoading}
            name="email"
            label="Ваш email"
            transformValue={applyInputTransformers([toLowerCaseValueTransformer, offSpacesValueTransformer])}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            disabled={isLoading}
            type={showPassword ? 'text' : 'password'}
            name="password"
            label="Пароль"
            transformValue={applyInputTransformers([offSpacesValueTransformer])}
            endAdornment={
              <IconButton aria-label="переключить видимость пароля" onClick={toggleShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
        </FieldWrapper>
        {errorText && <ErrorText>{errorText}</ErrorText>}
        <SubmitButton disabled={isLoading} variant="filled" type="submit" fullWidth>
          {isLoading ? <CircularProgress size="25px" /> : 'Войти'}
        </SubmitButton>
        <ExtraActions style={{ pointerEvents: isLoading ? 'none' : 'initial' }}>
          Ещё не зарегистрированы?{` `}
          <Link href={routes.registrationQuery(continueParamsFactory(continuePath || undefined))}>Регистрация</Link>
        </ExtraActions>
        <ExtraActions style={{ pointerEvents: isLoading ? 'none' : 'initial' }}>
          <Link href={routes.resetPassword}>Забыли пароль?</Link>
        </ExtraActions>
      </Form>
    </FormikProvider>
  );
};
