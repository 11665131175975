import styled from 'styled-components';
import { Button } from '@components/Button';

export const FieldWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.s};
`;

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

export const ErrorText = styled.div`
  margin-top: ${({ theme }) => theme.spaces.s};
  color: ${({ theme }) => theme.colors.error};
  overflow-x: auto;
`;

export const ExtraActions = styled.div`
  margin-top: ${({ theme }) => theme.spaces.s};
  opacity: 0.8;
  font-size: 14px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

export const SuccessRegistration = styled.div`
  margin-top: ${({ theme }) => theme.spaces.s};
  padding: ${({ theme }) => theme.spaces.s};
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  font-size: 12px;
`;
