import { useCallback, useMemo } from 'react';
import md5 from 'md5';
import { useRouter } from 'next/dist/client/router';
import * as Sentry from '@sentry/nextjs';
import { routes } from '../../routes';
import { LoginForm } from './Form';
import { LoginFormValues } from './Form/types';
import { loginUser } from '@http/endpoints/oauth';
import { NextPageFC, Nullable } from 'types';
import { REG_EMAIL } from 'types/routesQueryNames';
import { useContinuePath } from '@shared/hooks/use-continue-path';
import { saveBearerCookie } from '@shared/userAuth';

const Login: NextPageFC = () => {
  const router = useRouter();
  const search = router.query;
  const searchContinue = useContinuePath();
  const searchRegEmail = search[REG_EMAIL];

  const handleFormSubmit = useCallback(async (values: LoginFormValues) => {
    try {
      const data = await loginUser({
        username: encodeURIComponent(values.email.toLowerCase()),
        password: md5(values.password).toUpperCase(),
      });

      saveBearerCookie(data.bearer);
      await router.push(searchContinue || routes.home);
    } catch (err) {
      if (err.isAxiosError && typeof err.response?.data?.errorText === 'string') {
        throw new Error(err.response?.data.errorText);
      }

      Sentry.captureException(err);
      throw new Error('Неизвестная ошибка, пожалуйста обратитесь в поддержку');
    }
    // eslint-disable-next-line
  }, []);

  const registrationEmail: Nullable<string> = useMemo(() => {
    if (!searchRegEmail) return null;
    if (Array.isArray(searchRegEmail)) return searchRegEmail.join(',');
    return searchRegEmail;
  }, [searchRegEmail]);

  return <LoginForm onFormSubmit={handleFormSubmit} registrationEmail={registrationEmail} />;
};

export default Login;
