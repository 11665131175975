import React from 'react';
import Link from 'next/link';
import { routes } from '../../routes';
import { Wrapper, ContentWrapper, FormWrapper, LogoWrapper, Privacy, Title } from './styled';
import LogoSVGColors from 'assets/svg/logo.svg';

interface Props {
  title: string;
}

export const LoginFormsLayout: React.FC<Props> = ({ children, title }) => (
  <Wrapper>
    <ContentWrapper>
      <LogoWrapper>
        <Link href={routes.home}>
          <a style={{ width: '120px' }} id="custom-anchor">
            <LogoSVGColors />
          </a>
        </Link>
      </LogoWrapper>
      <FormWrapper>
        <Title>{title}</Title>
        <div>{children}</div>
      </FormWrapper>
      <Privacy>© {new Date().getFullYear()} Kritod, все права защищены</Privacy>
    </ContentWrapper>
  </Wrapper>
);
